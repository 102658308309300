(function ($, generic) {
  /**
   * Drupal behavior method to handle the waitlist overlay display and functionality.
   * After the 'product.waitlist.overlay' event is fired, the product's data is passed to the launch method.
   * The product_waitlist_overlay inline template is then rendered and displayed using generic.overlay.launch.
   * Once rendered, the waitlist form submit rpc is initialized.
   * Form submission either results in a success message or errors displaying inline.
   * All messaging copy is handled within the waitlist module in the CMS.
   */
  const launch = (data, context) =>  {
    const that = this;
    const rendered = site.template.get({
      name: 'product_waitlist_overlay',
      data: data
    });

    generic.overlay.launch({
      content: rendered,
      cssClass: 'notify-me-overlay-cbox',
      cssStyle: {
        height: '350px'
      },
      onComplete: function (response) {
        const $overlayCont = $('.js-waitlist-container', context);
        const $header = $('.js-waitlist-header', $overlayCont);
        const $formCont = $('.js-waitlist-form', $overlayCont);
        const $submit = $('.js-waitlist-submit', $overlayCont);
        const $email = $('.js-waitlist-email', $overlayCont);
        const $requestType = $('.js-waitlist-request-type', $overlayCont);
        const $skuBaseId = $('.js-waitlist-sku-base-id', $overlayCont);
        const $successCont = $('.js-waitlist-success', $overlayCont);
        const $errorList = $('.js-error-messages', $overlayCont);
        // Set request type.
        const requestType = data.INVENTORY_STATUS == statusIds.inventory.comingSoon ? 'CS' : 'BIS'; // Default is BIS due to TOS for other locales

        $requestType.val(requestType);
        // Set sku_base_id in.
        $skuBaseId.val(data.SKU_BASE_ID);

        $formCont.on('submit', function (e) {
          e.preventDefault();

          const pObj = {};
          const params = $(this).serializeArray();

          for (let i = 0, len = params.length; i < len; i++) {
            pObj[params[i].name] = params[i].value;
          }

          generic.jsonrpc.fetch({
            method: 'form.get',
            params: [pObj],
            onSuccess: function (r) {
              $header.hide();
              $formCont.hide();
              $successCont.show();
            },
            onFailure: function (r) {
              const errorObj = r.getError();
              const errorArray = errorObj.data.messages;

              generic.showErrors(errorArray, $errorList);
              if (window.site.client.isMobile) {
                $.colorbox.resize({
                  height: '100%'
                });
              }
            }
          });
        });
      }
    });
  };

  /**
   * Status ids used by the waitlist overlay; adjust accordingly.
   */
  const statusIds = {
    inventory: {
      comingSoon: 3
    }
  };

  Drupal.behaviors.productWaitlistOverlayV1 = {
    attach: (context) => {
      /**
       * Event listener to display the waitlist overlay.
       * Event trigger comes from module product_waitlist_v1.
       */
      $(document).on('product.waitlist.overlay', (event, data) => {
        launch(data, context);
      });
    }
  };
})(jQuery, generic);
